<template>
  <v-tooltip class="px-2" color="rgba(0, 0, 0, 0.85)" top>
    <template v-slot:activator="{on, attrs}">
      <div v-bind="attrs"
        v-on="on">
        <slot name="content">
      </slot>
      </div>
    </template>
    <div class="sub-body-bold" t-data="tooltip-title">Title</div>
    <div class="body-regular" t-data="tooltip-th-content">
      {{
        contents[0].code === 'th'
          ? contents[0][keyName]
          : contents[1][keyName]
      }}
    </div>
    <div class="body-regular" t-data="tooltip-en-content">
      {{
        contents[0].code === 'en'
          ? contents[0][keyName]
          : contents[1][keyName]
      }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipTitle',
  props: {
    contents: {
      type: Array,
      required: true
    },
    keyName: {
      type: String,
      required: false,
      default: 'title'
    },
  }
}
</script>
<style scoped>
.v-tooltip__content {
  padding: 8px 16px !important;
}
</style>