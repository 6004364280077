<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="455">
      <v-card class="edit-container">
        <v-card-title class="headline text-truncate" t-data="page-headline">
          <h4>
            Edit Order No.
          </h4>
        </v-card-title>
        <v-card-text class="py-0">
          <div
            class="fix-line-height body-regular secondaryTextColor--text"
            t-data="subtitle"
          >
            Order No. {{ data.indexNo + 1 }}
          </div>
          <div
            class="fix-line-height body-bold primaryTextColor--text pb-4"
            t-data="content-title"
          >
            {{ getContentByLang(data.contents, 'th') }}
            <br />
            {{ getContentByLang(data.contents, 'en') }}
          </div>
          <v-divider></v-divider>
          <v-form class="pt-4" ref="form" t-data="info-input" lazy-validation>
            <p class="edit-field-label sub-body-bold" t-data="info-label">
              New Order No.
            </p>
            <v-text-field
              outlined
              dense
              class="subtitle-2 can-disable-selector"
              t-data="form-value"
              v-model="newOrderNo"
              placeholder="Input New Order No."
              @keypress="isNumber"
              :rules="[
                v =>
                  (v <= data.total && v > 0) ||
                  'Unable to display entered numbers. Please enter again correctly.'
              ]"
            ></v-text-field>
          </v-form>
          <v-alert
            class="alert-container align-self-center"
            t-data="alert-container"
            color="info200Color"
            dense
            icon="mdi-information"
          >
            Modified order display will be saved over the original.<br />
            and cannot be recalled</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="alert-cancel"
            class="cancel-button button-bold"
            t-data="cancel-btn"
            color="info600Color"
            text
            @click="cancel"
          >
            Cancel</v-btn
          >
          <v-btn
            id="alert-confirm"
            class="confirm-button button-bold"
            t-data="confirm-btn"
            color="info600Color"
            text
            @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'EditOrderDialog',
  props: {
    show: {type: Boolean},
    data: {type: Object},
    close: {type: Function},
    titleKey: {type: String, required: false, default: "title"},
  },
  data: () => ({
    newOrderNo: null
  }),
  methods: {
    clearInput() {
      this.newOrderNo = null
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    cancel() {
      this.clearInput()
      this.close()
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('save', {
          oldOrderNo: this.data.indexNo + 1,
          newOrderNo: this.newOrderNo
        })
        this.clearInput()
      }
    },
    isNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    getContentByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0][this.titleKey]
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.fix-line-height {
  line-height: 28px;
}
.alert-container {
  color: var(--info-800-color) !important;
  font-size: 0.875rem;
  padding: 6px 10px;
}
</style>

<style lang="scss">
.alert-container > .v-alert__wrapper > .theme--light.v-icon {
  color: var(--info-500-color) !important;
  font-size: 1.25rem;
  margin-right: 10px;
  align-self: center !important;
}
</style>
