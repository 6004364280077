<template>
  <div>
    {{ userText }}
  </div>
</template>

<script>
export default {
  name: 'UsernameStatusText',
  props: {
    user: {type: Object || null},
  },
  computed: {
    userText() {
      let text = ''
      if (!!this.user && 'name' in this.user) {
        text += this.user.name
        if ('is_active' in this.user && this.user.is_active === false) {
          text += ' (Inactive)'
        }
      }
      return text
    }
  }
}
</script>

