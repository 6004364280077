<template>
  <v-text-field
    id="search-text-box"
    t-data="search-text-field"
    v-model="data"
    filled
    background-color="grayscale"
    prepend-inner-icon="search"
    :label="label"
    single-line
    hide-details
    clearable
    solo
    flat
    dense
    color="primary800Color"
    class="subtitle-2 green--text"
    @click:clear="clearSearch()"
  ></v-text-field>
</template>

<script>
export default {
  name: 'Search',
  props: {
    search: {type: String},
    label: {type: String}
  },
  computed: {
    data: {
      get: function() {
        return this.search
      },
      set: function(value) {
        const searchValue = value ? value : ''
        this.$emit('input', searchValue)
      }
    }
  },
  methods: {
    clearSearch() {
      this.$emit('clear')
    }
  }
}
</script>

<style scoped>
.theme--light.v-input.search-field input::placeholder {
  color: rgb(200, 255, 0) !important;
}

.v-text-field::v-deep label {
  max-width: 100%;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--disable-text-color) !important;
}

.v-text-field::v-deep input {
  font-size: 16px !important;
  font-weight: 400 !important;
}
</style>