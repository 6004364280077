<template>
  <v-menu
    v-model="isOpenMenu"
    offset-y
    bottom
    left
    :close-on-content-click="false"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        id="filter-btn"
        t-data="filter-btn"
        color="primary500Color"
        outlined
        v-bind="attrs"
        v-on="on"
        :class="{'sub-body-bold': true, hasFiltered: hasFiltered}"
      >
        <v-icon left>
          mdi-filter-outline
        </v-icon>
        FILTER{{countFiltered > 0 ? `: ${countFiltered}` : ''}}
      </v-btn>
    </template>
    <v-list min-width="315">
      <v-list-item class="mb-2">
        <v-list-item-title class="filterTitle mt-2" t-data="filter-title">
          <h6>Filter</h6>
        </v-list-item-title>
      </v-list-item>

      <slot name="content"></slot>

      <v-list-item-action
        class="mt-5 mb-2 ml-6 mr-6 d-flex flex-row"
      >
        <v-btn
          t-data="clear-btn"
          color="info600Color"
          class="sub-body-bold mr-auto"
          :disabled="disabledClear"
          text
          @click="handleClearFilter()"
        >
          CLEAR
        </v-btn>
        <v-btn
          t-data="cancel-btn"
          id="filter-cancel"
          color="info600Color"
          class="sub-body-bold"
          text
          @click="handleCancelFilter()"
        >
          CANCEL
        </v-btn>
        <v-btn
          t-data="apply-btn"
          id="filter-apply"
          color="info600Color"
          class="sub-body-bold"
          text
          :disabled="disabledApply"
          @click="handleApplyFilter()"
        >
          APPLY
        </v-btn>
      </v-list-item-action>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'FilterDropdown',
  data: () => ({
    isOpenMenu: false,
    isCancel: false,
    isApply: false,
  }),
  props: {
    countFiltered: {type: Number},
    disabledClear: {type: Boolean},
    disabledApply: {type: Boolean},
  },
  computed: {
    hasFiltered() {
      return this.countFiltered > 0
    }
  },
  methods: {
    handleClearFilter() {
      this.$emit('clear')
    },
    handleCancelFilter() {
      this.isCancel = true
      this.$emit('cancel')
      this.isOpenMenu = false
    },
    handleApplyFilter() {
      this.isApply = true
      this.$emit('apply')
      this.isOpenMenu = false
    },
  },
  watch: {
    isOpenMenu() {
      const CLICK_OUTSIDE = !this.isOpenMenu && !this.isApply && !this.isCancel
      if (CLICK_OUTSIDE) {
        this.$emit('cancel')
      }
      this.isCancel = false
      this.isApply = false
    }
  }
}
</script>
