<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="95%">
      <div style="background: white">
        <v-row no-gutters style="padding: 10px">
          <v-col cols="12" sm="11"></v-col>
          <v-col cols="12" sm="1">
            <v-btn t-data="close-btn" @click="close">close</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-card>
        <v-row t-data="image-department" justify="center" style="padding-top: 20px">
          <v-img
              v-bind:src="image"
              lazy-src="{image}"
              class="grey lighten-2"
              max-width="200px"
              contain
              max-height="200px"
          ></v-img>
        </v-row>
        <v-row t-data="hospital-name" justify="center" style="margin-top: 10px">
          <p>{{hospitalName}}</p>
        </v-row>
        <v-row style="margin: 20px">
          <v-col cols="12" sm="6">
            <v-row justify="center">
              <h3 t-data="title-th">{{ thaiContent.title }}</h3>
            </v-row>
            <v-row t-data="description-th" style="margin-top: 10px">
              <p v-html="thaiContent.description"></p>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row justify="center">
              <h3 t-data="title-en">{{ engContent.title }}</h3>
            </v-row>
            <v-row t-data="description-en" style="margin-top: 10px">
              <p v-html="engContent.description"></p>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {isUrl} from '../helper/helper'

export default {
  name: 'DepartmentPreview',
  props: {
    closePreivew: {type: Function},
    show: {type: Boolean},
    data: {type: Object},
  },
  data () {
    return {
    }
  },
  computed: {
    image: function() {
      if (isUrl(this.data.image)) {
        return `${this.data.image}?${new Date().getTime()}`
      } else {
        return this.data.image
      }
    },
    thaiContent: function () {
      if (Array.isArray(this.data.contents)) {
        const content = this.data.contents.find(content => content.code === 'th')
        return content ? content : {tile: '', description: ''}
      }
      return {tile: '', description: ''}
    },
    engContent: function () {
      if (Array.isArray(this.data.contents)) {
        const content = this.data.contents.find(content => content.code === 'en')
        return content ? content : {tile: '', description: ''}
      }
      return {tile: '', description: ''}
    },
    hospitalName: function () {
      return `${this.data.organization.name} ( ${this.data.organization.codename} )`
      // if (Array.isArray(this.data.contents)) {
      //   const content = this.data.contents.find(content => content.code === 'en')
      //   return content ? content : {tile: '', description: ''}
      // }
      // return {tile: '', description: ''}
    },
  },
  mounted() {},
  methods: {
    close() {
      this.closePreivew()
    }
  }
}
</script>

<style scoped>
.container {
  border-color: gray;
  padding-top: 20px;
}
.underline {
  text-decoration: line-through;
}
.text_price {
  color: deepskyblue;
}
</style>
